const edit = {
  fields: [
    {
      type: 'mixed',
      name: 'title',
      label: 'Title',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'mixed',
      name: 'body',
      label: 'Body',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
          rich: true,
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
          rich: true,
        },
      ],
    },
  ],
  main: true,
  breadcrumb: 'Privacy Policy',
  title: 'Privacy Policy',
};

const data = { edit };

export default data;
