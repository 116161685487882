import axios from 'axios';
import store from '../store';

const api = axios.create({
    baseURL: '/',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Cookie: `token= ${localStorage.getItem('token')}`
        // 'Authorization': localStorage.getItem('token')
    },
});

api.interceptors.response.use(
    (res) => res,
    (err) => {
        if (err.response.data.message === 'Token is not valid') {
            // store.dispatch({ type: LOGOUT });
            console.log("LOGOUT")
        }
        return Promise.reject(err);
    },
);

export default api;