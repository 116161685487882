import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import Icon from '../layouts/Icon';

const Background = ({ field, value, updateForm, show, video }) => {
  const { label, name } = field;
  const [type, setType] = useState(null);
  const check = typeof value === 'string' || value instanceof String;
  const display = check ? value.length > 0 : false;
  return show ? (
    <Box mb={3}>
      <Typography variant='h6' component='h6' mb={2} fontWeight='bold'>
        {field.label}
      </Typography>
      <Box
        className='img-thumbnail contain'
        sx={{
          height: '200px',
          width: '300px',
          maxWidth: '300px',
          maxHeight: '200px',
          position: 'relative',
          marginBottom: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {video ? (
          <video
            src={value}
            style={{ height: '100%', width: '100%' }}
            controls
            alt={value}
          ></video>
        ) : (
          <img
            src={value}
            style={{ height: '100%', width: '100%' }}
            alt={value}
          />
        )}
      </Box>
      <Divider />
    </Box>
  ) : (
    <>
      <Stack direction='row' alignItems='center' gap={2}>
        {display && (
          <div>
            <label
              className={`form-label ${
                field.required ? 'form-input-required' : ''
              }`}
              htmlFor={name}
            >
              {label}
            </label>
            <Box
              className='img-thumbnail contain'
              sx={{
                height: '200px',
                width: '300px',
                maxWidth: '300px',
                maxHeight: '200px',
                position: 'relative',
                marginBottom: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {video ? (
                <video
                  src={value}
                  style={{ height: '100%', width: '100%' }}
                  controls
                  alt={value}
                ></video>
              ) : (
                <img
                  src={value}
                  style={{ height: '100%', width: '100%' }}
                  alt={value}
                />
              )}
            </Box>
          </div>
        )}
        {value && (
          <>
            {value.current && (
              <div>
                <label className={`form-label`} htmlFor={name}>
                  Current
                </label>
                <Box
                  className='img-thumbnail contain'
                  sx={{
                    height: '200px',
                    width: '300px',
                    maxWidth: '300px',
                    maxHeight: '200px',
                    position: 'relative',
                    marginBottom: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {video ? (
                    <video
                      src={value.current}
                      style={{ height: '100%', width: '100%' }}
                      controls
                      alt={value.current}
                    ></video>
                  ) : (
                    <img
                      src={value.current}
                      style={{ height: '100%', width: '100%' }}
                      alt={value.current}
                    />
                  )}
                </Box>
              </div>
            )}
            {value.preview && (
              <div>
                <label className={`form-label`} htmlFor={name}>
                  Preview
                </label>
                <Box
                  className='img-thumbnail contain'
                  sx={{
                    height: '200px',
                    width: '300px',
                    maxWidth: '300px',
                    maxHeight: '200px',
                    position: 'relative',
                    marginBottom: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {type == 'video' && (
                    <video
                      src={value.preview}
                      style={{ height: '100%', width: '100%' }}
                      controls
                      alt={value.preview}
                    ></video>
                  )}
                  {type == 'image' && (
                    <img
                      src={value.preview}
                      style={{ height: '100%', width: '100%' }}
                      alt={value.preview}
                    />
                  )}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      textAlign: 'right',
                      padding: 1,
                    }}
                  >
                    <IconButton
                      sx={{
                        color: 'red',
                        '&:hover': {
                          color: 'darkred',
                          transition: 'all 0.3s',
                        },
                      }}
                      aria-label='delete'
                      onClick={() => {
                        setType(null);
                        updateForm(name, {
                          data: null,
                          preview: null,
                        });
                      }}
                    >
                      <Icon type={'Cancel'} color={'red'} />
                    </IconButton>
                  </Box>
                </Box>
              </div>
            )}
          </>
        )}
      </Stack>
      <div className='form-control'>
        <label
          className={`form-label ${
            field.required ? 'form-input-required' : ''
          }`}
          htmlFor={name}
        >
          {label}
        </label>
        <input
          className='form-input'
          type='file'
          accept='webm/mp4/*'
          required={field?.required}
          name={name}
          id={name}
          onChange={(e) => {
            const reader = new FileReader();
            setType(e.target.files[0].type.split('/')[0]);
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = () => {
              const img = {
                type: e.target.files[0].type.split('/')[0],
                current: display ? value : null,
                preview: URL.createObjectURL(e.target.files[0]),
                data: reader.result,
              };
              console.log(img);
              updateForm(name, img);
            };
          }}
        />
      </div>
    </>
  );
};

export default Background;
