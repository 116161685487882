import Select from 'react-select';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Divider, Typography } from '@mui/material';

const SelectList = ({ field, value, updateForm, show }) => {
  const { label, name } = field;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getData = async () => {
      if (field.async) {
        const res = await axios.get(
          `/admin/select/${field.route ? field.route : name}`,
        );
        setOptions(res.data.data);
        // console.log(res.data.data.filter((a) => value.some((b) => a.value === b.value)))
      } else {
        setOptions(field.values ? field.values : []);
      }
    };
    if (!show) getData();
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '0px',
      transition:
        'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      color: '#454655',
      fontSize: '14px',
      lineHeight: '24px',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      lineHeight: '24px',
    }),
    input: (provided, state) => ({
      ...provided,
      boxSizing: 'border-box',
      color: '#454655',
      background: 'transparent',
      fontSize: '14px',
      lineHeight: '24px',
      outline: 'none',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '4px',
      paddingBottom: '4px',
    }),
    multiValue: (provided, state) => ({
      ...provided,
      borderRadius: '0',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      borderRadius: '0',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      lineHeight: '24px',
    }),
    menu: (provided, state) => ({
      ...provided,
      borderRadius: '0',
      width: '99%',
      marginLeft: '5px',
    }),
  };

  return show ? (
    <Box mb={3}>
      <Typography variant='h6' component='h6' mb={2} fontWeight='bold'>
        {field.label}
      </Typography>
      <Typography mb={3}>
        <Select
          options={value}
          styles={customStyles}
          placeholder={field.placeholder ? field.placeholder : ''}
          isMulti={field?.multiple}
          isDisabled={true}
          menuPlacement='auto'
          value={value}
        />
      </Typography>
      <Divider />
    </Box>
  ) : (
    <div className='form-control'>
      <label
        className={`form-label ${field.required ? 'form-input-required' : ''}`}
        htmlFor={name}
      >
        {label}
      </label>
      <Select
        options={options}
        onChange={(value) => {
          console.log(value);
          updateForm(name, field?.multiple ? value : value.value);
        }}
        styles={customStyles}
        placeholder={field.placeholder ? field.placeholder : ''}
        isMulti={field?.multiple}
        isDisabled={field?.disabled}
        menuPlacement='auto'
        value={
          field?.multiple
            ? options.filter((a) => value?.some((b) => a.value === b.value))
            : options.filter((option) => option.value == value)[0]
        }
      />
    </div>
  );
};

export default SelectList;
