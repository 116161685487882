import { Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const Empty = ({ label }) => {
    return (
        <Box mb={3} >
            <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                {label}
            </Typography>
            <Typography mb={3} >
                N/A
            </Typography>
            <Divider />
        </Box>
    )
}

export default Empty