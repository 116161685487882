import { createSlice } from '@reduxjs/toolkit';
import properties from './properties';
import { get } from './actions';

// State on app load and refresh
const initialState = {
  items: [], // will hold the list of items
  itemsLoading: true,
  item: null, // will hold one item for edit
  itemLoading: true,
  properties,
};

// Create the Users Slice
const joinusSlice = createSlice({
  name: 'joinus',
  initialState,
  reducers: {
    // Resets the state but keeps the data
    reset: (state) => {
      state.items = null; // will hold the list of items
      state.itemsLoading = false;
      state.item = null; // will hold one item for edit
      state.itemLoading = false;
    },
  },
  // pending: request send but response hasn't returned yet
  // fullfilled: response returned with successfull status code
  // rejected: request failed or response returned with error status code
  extraReducers: (builder) => {
    builder
      .addCase(get.pending, (state) => {
        state.itemLoading = true;
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.itemLoading = false;
        state.item = payload.data;
      })
      .addCase(get.rejected, (state) => {
        state.itemLoading = false;
      });
  },
});

// Export the slice actions
export const { reset } = joinusSlice.actions;

// Export the slice reducer
export default joinusSlice.reducer;
