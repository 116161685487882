import { createAsyncThunk } from '@reduxjs/toolkit';
// Import Axios Admin API (this is where the baseURL is and shared reuqest params are set)
import api from '../../utils/api';
import { setAlert } from '../alerts/actions';

export const list = createAsyncThunk(
  'home-items/list',
  async (
    params = {
      page: 0,
      limit: 10,
      orderBy: null,
      orderDirection: null,
      filter: null,
      search: null,
    },
  ) => {
    try {
      const page = params.page ? params.page : 0;
      const limit = params.limit ? params.limit : 10;
      const res = await api.get(`/admin/home-items`, { params });
      console.log(page, limit);
      return { ...res.data, page, limit };
    } catch (error) {
      console.log(error);
    }
  },
);

export const create = createAsyncThunk(
  'home-items/create',
  async ({ data, navigate }, { dispatch }) => {
    try {
      const res = await api.post('/admin/home-items', data);
      dispatch(list());
      dispatch(
        setAlert({
          message: 'Home item Added successfully',
          alertType: 'success',
        }),
      );
      if (navigate) navigate(`/cms/home items`);
      return res.data;
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);

export const get = createAsyncThunk(
  'home-items/get',
  async (id, { dispatch }) => {
    try {
      const res = await api.get(`/admin/home-items/${id}`);
      return res.data;
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);

export const edit = createAsyncThunk(
  'home-items/edit',
  async ({ id, data, navigate }, { dispatch }) => {
    try {
      console.log(data);
      await api.put(`/admin/home-items/${id}`, data);
      dispatch(
        setAlert({
          message: 'Home item updated successfully',
          alertType: 'success',
        }),
      );
      dispatch(list());
      if (navigate) navigate(`/cms/home items`);
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);

export const order = createAsyncThunk(
  'home-items/order',
  async (data, { dispatch }) => {
    try {
      console.log(data);
      await api.post(`/admin/home-items/order`, { list: data });
      dispatch(
        setAlert({
          message: 'home-items order updated successfully',
          alertType: 'success',
        }),
      );
      dispatch(
        list({ page: -1, limit: -1, orderBy: 'order', orderDirection: 'ASC' }),
      );
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);

export const remove = createAsyncThunk(
  'home-items/remove',
  async (id, { dispatch }) => {
    try {
      await api.delete(`/admin/home-items/${id}`);
      dispatch(
        setAlert({
          message: 'Home item removed successfully',
          alertType: 'error',
        }),
      );
      dispatch(list());
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);

export const bulk_remove = createAsyncThunk(
  'home-items/bulk-remove',
  async ({ data }, { dispatch }) => {
    try {
      await api.post(`/admin/home-items/delete`, data);
      dispatch(
        setAlert({
          message: 'Home items removed successfully',
          alertType: 'error',
        }),
      );
      dispatch(list());
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);
