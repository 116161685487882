import Main from './components/pages/Main';

import Box from '@mui/material/Box';
import { Route, Routes } from 'react-router-dom';
import List from './components/pages/default/List';
import Show from './components/pages/default/Show';
import Form from './components/pages/default/Form';
import Order from './components/pages/default/Order';

// Custom Pages
import RolesForm from './components/pages/roles/Form';

import AdminsPage from './components/pages/admins';
import PermissionsPage from './components/pages/permissions';
import NewsPage from './components/pages/news';
import BannersPage from './components/pages/banners';
import PartnersPage from './components/pages/partners';
import QuotesPage from './components/pages/quotes';
import ApplicationsPage from './components/pages/applications';
import PrivacyPolicyPage from './components/pages/privacy-policy';
import ContactUsPage from './components/pages/contact-us';
import FindUsPage from './components/pages/findus';
import CategoriesPage from './components/pages/categories';
import PositionsPage from './components/pages/positions';
import FaqsPage from './components/pages/faqs';
import StepsPage from './components/pages/steps';
import ScoreCardsPage from './components/pages/score-cards';
import ManufacturingPage from './components/pages/manufacturing';
import SustainabilityPage from './components/pages/sustainability';
import JoinUsPage from './components/pages/join-us';
import SupportPage from './components/pages/support';
import HomeInformativePage from './components/pages/home-informative';
import AboutUsPage from './components/pages/about-us';
import MissionPage from './components/pages/mission';
import HomeItemsPage from './components/pages/home-items';
import ProductsPage from './components/pages/products';
import RecipientsPage from './components/pages/recipients';
import ChallengesPage from './components/pages/challenges';
import ClimateChangePage from './components/pages/climate-change';
import LinksPage from './components/pages/links';
import TermsPage from './components/pages/terms';
import SMTPPage from './components/pages/smtp';
import RolesPage from './components/pages/roles';
import Alerts from './components/layouts/Alerts';
import { createTheme, ThemeProvider } from '@mui/material';
import Login from './components/pages/Login';
import AuthRoute from './components/layouts/AuthRoute';
import NotFound from './components/layouts/NotFound';
import Profile from './components/pages/auth/Profile';
import NotAuthorized from './components/layouts/NotAuthorized';

const THEME = createTheme({
  typography: {
    fontFamily: `Open Sans,sans-serif`,
  },
});

const App = ({ props }) => {
  return (
    <ThemeProvider theme={THEME}>
      <Box sx={{ display: 'flex' }} color='black'>
        <Alerts />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/cms/login' element={<Login />} />
          <Route
            path='/cms'
            element={
              <AuthRoute redirectTo='login'>
                <Main props={props} />
              </AuthRoute>
            }
          >
            {/* Users page */}
            <Route path='profile' element={<Profile />} exact />

            <Route path='admins' element={<AdminsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='permissions' element={<PermissionsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='home items' element={<HomeItemsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='steps' element={<StepsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='score cards' element={<ScoreCardsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='products' element={<ProductsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='partners' element={<PartnersPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='contact us' element={<ContactUsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
            </Route>

            <Route path='quotes' element={<QuotesPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
            </Route>

            <Route path='applications' element={<ApplicationsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
            </Route>

            <Route path='news' element={<NewsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='categories' element={<CategoriesPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='positions' element={<PositionsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='challenges' element={<ChallengesPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='recipients' element={<RecipientsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='climate change' element={<ClimateChangePage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='faqs' element={<FaqsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='banners' element={<BannersPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='find us' element={<FindUsPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>
            <Route path='join us' element={<JoinUsPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='smtp' element={<SMTPPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route
              path='home informative'
              element={<HomeInformativePage />}
              exact
            >
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='about us' element={<AboutUsPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='mission' element={<MissionPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='manufacturing' element={<ManufacturingPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='sustainability' element={<SustainabilityPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='privacy policy' element={<PrivacyPolicyPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='support' element={<SupportPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>
            <Route path='links' element={<LinksPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>
            <Route path='terms' element={<TermsPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='roles' element={<RolesPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<RolesForm type='create' />} exact />
              <Route
                path=':id/edit'
                element={<RolesForm type='edit' />}
                exact
              />
            </Route>
          </Route>
          <Route path='unauthorized' element={<NotAuthorized />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
};

export default App;
