import { Box, Divider, Typography, Switch as SwitchIput } from '@mui/material'
import React from 'react'

const Switch = ({ field, value, updateForm, show }) => {
    const { label, name } = field
    return (
        show ? <Box mb={3} >
            <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                {label}
            </Typography>
            <SwitchIput checked={value} sx={{ marginBottom: 3 }} />
            <Divider />
        </Box> : <Box mb={3} >
            <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                {label}
            </Typography>
                <SwitchIput name={name} id={name} checked={value} sx={{ marginBottom: 3 }} onChange={e => {console.log(e.target); updateForm(name, e.target.checked)}} />
            <Divider />
        </Box>
    )
}

export default Switch