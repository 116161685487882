const list = {
  header: [
    {
      id: 'image',
      display: true,
      label: 'Image',
      align: 'left',
    },
    {
      id: 'title.en',
      label: 'Title',
      orderBy: 'title',
      align: 'left',
    },
    {
      id: 'publish',
      label: 'Publish',
      align: 'left',
      switch: true,
    },
  ],
  permission: 'list',
  actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'mixed',
      name: 'title',
      label: 'Title',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'mixed',
      name: 'body',
      label: 'Body',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
          rich: true,
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
          rich: true,
        },
      ],
    },
    {
      type: 'switch',
      name: 'publish',
      label: 'Published',
      required: true,
    },
  ],
  permission: 'show',
};
const create = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'mixed',
      name: 'title',
      label: 'Title',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'mixed',
      name: 'body',
      label: 'Body',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
          rich: true,
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
          rich: true,
        },
      ],
    },
  ],
};
const edit = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'mixed',
      name: 'title',
      label: 'Title',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'mixed',
      name: 'body',
      label: 'Body',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
          rich: true,
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
          rich: true,
        },
      ],
    },
  ],
};

const order = {
  fields: [
    {
      id: 'image',
      display: true,
      label: 'Image',
      align: 'left',
    },
    {
      id: 'title',
      label: 'Title',
      child: 'en',
      align: 'left',
    },
  ],
  permission: 'order',
};

const search = {
  fields: [
    {
      type: 'text',
      name: 'title',
    },
  ],
};

const data = { list, create, edit, show, order, search };

export default data;
