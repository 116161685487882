const list = {
    header: [
        {
            id: 'name',
            label: 'Name',
            align: 'left',
        },
        {
            id: 'action',
            label: 'Action',
            align: 'left',
        },


    ],
    actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
    fields: [
        {
            type: "text",
            name: "name",
            label: "Name",
            required: true,
        },
        {
            type: "text",
            name: "action",
            label: "Action",
            required: true,
        }
    ]
};
const create = {
    fields: [
        {
            type: "text",
            name: "name",
            label: "Name",
            required: true,
        }, {
            type: "text",
            name: "action",
            label: "Action",
            required: true,
        }
    ]
};
const edit = {
    fields: [
        {
            type: "text",
            name: "name",
            label: "Name",
            required: true,
        }, {
            type: "text",
            name: "action",
            label: "Action",
            required: true,
        }
    ]
};
const filter = {
    fields: [
        {
            type: "text",
            name: "name",
            label: "Name",
        }, {
            type: "text",
            name: "action",
            label: "Action",
        }
    ]
};

const search = {
    fields: [
        {
            type: "text",
            name: "name",
        },
        {
            type: "text",
            name: "action",
        },
    ]
}


const data = { list, create, edit, filter, show, search }

export default data;