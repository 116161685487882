export const initialState = (fields) => {
    const res = {};
    fields?.forEach(field => {
        const { type, name } = field;
        if (type === 'text' || type === 'tel' || type === 'email')
            res[name] = ''
        else if (type === 'image') {
            res[name] = { preview: null, data: null }
        }
        else if (type === 'password') {
            res[name] = ''
            res[name + "_confirm"] = ''
        }
        else if (type === 'array')
            res[name] = []
        else if (type === 'mixed') {
            res[name] = {}
            for (const element of field.elements) {
                res[name][element.name] = ''
            }
        }
        else if (type === 'map') {
            res[name] = {
                [(field?.long?.name) ? field?.long?.name : 'long']: null,
                [(field?.lat?.name) ? field?.lat?.name : 'lat']: null,
            }
            if (field.noLabel !== true) {
                res[name][(field?.name?.name) ? field?.name?.name : 'name'] = null
            }
        }
        else
            res[name] = null;
    }
    );
    return res;
}

export const initialFilterState = (fields) => {
    const res = {};
    fields?.forEach(field => {
        const { type, name } = field;
        if (type === 'text' || type === 'tel' || type === 'email')
            res[name] = ''
        else
            res[name] = null;
    }
    );
    return res;
}

export const authorized = (permissions, name, action) => {
    return permissions.filter(permission => permission.name === name && (action ? (permission.action === action) : true)).length > 0
}