import { createSlice } from "@reduxjs/toolkit";
import { setAlert } from "./actions";

// State on app load and refresh
const initialState = {
    list: []
};

// Create the Users Slice
const alertsSlice = createSlice({
    name: "alerts",
    initialState,
    reducers: {
        pop: (state) => {
            state.list = state.list.slice(1);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setAlert.pending, (state) => {
        })
            .addCase(setAlert.fulfilled, (state, { payload }) => {
                state.list = [...state.list, payload]
            })
            .addCase(setAlert.rejected, (state) => {
            })
    }

});

// Export the slice actions
export const { pop } = alertsSlice.actions;

// Export the slice reducer
export default alertsSlice.reducer;
