import { Box, Divider, IconButton, Link, Typography } from '@mui/material';
import React from 'react';
import Icon from '../layouts/Icon';
import DownloadIcon from '@mui/icons-material/Download';

const Image = ({ field, value, updateForm, show }) => {
  const { label, name } = field;
  const check = typeof value === 'string' || value instanceof String;
  const display = check ? value.length > 0 : false;
  return show ? (
    <Box mb={3}>
      <Typography variant='h6' component='h6' mb={2} fontWeight='bold'>
        {field.label}
      </Typography>
      <Link
        href={value}
        target='_blank' // Opens the link in a new tab/window
        download // Adds the "download" attribute to the link, triggering the download when clicked
        style={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
        }}
        mb={3}
      >
        <DownloadIcon sx={{ mr: 1 }} />
        Download File
      </Link>
      <Divider />
    </Box>
  ) : (
    <>
      {display && (
        <>
          <label
            className={`form-label ${
              field.required ? 'form-input-required' : ''
            }`}
            htmlFor={name}
          >
            {label}
          </label>
          <Box
            sx={{
              marginBottom: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography>{value}</Typography>
            {!field?.required && (
              <Box
                sx={{
                  padding: 1,
                }}
              >
                <IconButton
                  sx={{
                    color: 'red',
                    '&:hover': {
                      color: 'darkred',
                      transition: 'all 0.3s',
                    },
                  }}
                  aria-label='delete'
                  onClick={() => {
                    updateForm(name, {
                      data: -1,
                      preview: null,
                    });
                  }}
                >
                  <Icon type={'Cancel'} color={'red'} />
                </IconButton>
              </Box>
            )}
          </Box>
        </>
      )}
      <div className='form-control'>
        <label
          className={`form-label ${
            field.required ? 'form-input-required' : ''
          }`}
          htmlFor={name}
        >
          {label}
        </label>
        <input
          className='form-input'
          type='file'
          required={field?.required}
          name={name}
          id={name}
          onChange={(e) => {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = () => {
              const img = {
                current: display ? value : null,
                preview: URL.createObjectURL(e.target.files[0]),
                data: reader.result,
              };
              console.log(img);
              updateForm(name, img);
            };
          }}
        />
      </div>
    </>
  );
};

export default Image;
