
const profile = {
    fields: [
        {
            type: "image",
            name: "image",
            label: "Image",
            required: false
        },
        {
            type: "text",
            name: "first_name",
            label: "First Name",
            required: true
        },
        {
            type: "text",
            name: "last_name",
            label: "Last Name",
            required: true
        },
        {
            type: "email",
            name: "email",
            label: "Email",
            required: true
        },
        {
            type: "select",
            async: true,
            name: "Roles",
            label: "Role",
            multiple: true,
            required: true
        },
        {
            type: "password",
            name: "old_password",
            label: "Old Password",
        },
        {
            type: "password",
            name: "password",
            label: "Password",
            confirm: true
        },
    ]
};


const data = { profile }

export default data;