import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  list,
  create,
  edit,
  remove,
  order,
  get,
  bulk_remove,
} from '../../../features/categories/actions';

const index = () => {
  const page = 'Categories';
  return (
    <div style={{ height: '100%', paddingTop: '4rem' }}>
      <Outlet
        context={[
          { page, list, create, get, edit, remove, order, bulk_remove },
        ]}
      />
    </div>
  );
};

export default index;
