const edit = {
  fields: [
    {
      type: 'video',
      name: 'image',
      label: 'Background',
    },
    {
      type: 'image',
      name: 'products_background',
      label: 'Products Background',
      required: true,
    },
    {
      type: 'image',
      name: 'items_background',
      label: 'Items Background',
      required: true,
    },
    {
      type: 'image',
      name: 'stats_background',
      label: 'Stats Background',
      required: true,
    },
    {
      type: 'mixed',
      name: 'banner_text_1',
      label: 'Banner Text 1',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'mixed',
      name: 'banner_text_2',
      label: 'Banner Text 2',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'text',
      name: 'video_link',
      label: 'Video Link',
    },
    {
      type: 'mixed',
      name: 'video_text',
      label: 'Video Text',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'text',
      name: 'glasses',
      label: 'Glasses',
    },
    {
      type: 'text',
      name: 'employees',
      label: 'Employees',
    },
    {
      type: 'text',
      name: 'partners',
      label: 'Partners',
    },
    {
      type: 'text',
      name: 'year',
      label: 'Year',
    },
  ],
  main: true,
  breadcrumb: 'Home Informative',
  title: 'Home Informative',
};

const data = { edit };

export default data;
