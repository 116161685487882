import { createAsyncThunk } from '@reduxjs/toolkit';
// Import Axios Admin API (this is where the baseURL is and shared reuqest params are set)
import api from '../../utils/api';
import { setAlert } from '../alerts/actions';

export const list = createAsyncThunk(
  'positions/list',
  async (
    params = {
      page: 0,
      limit: 10,
      orderBy: null,
      orderDirection: null,
      filter: null,
      search: null,
    },
  ) => {
    try {
      const page = params.page ? params.page : 0;
      const limit = params.limit ? params.limit : 10;
      const res = await api.get(`/admin/positions`, { params });
      console.log(page, limit);
      return { ...res.data, page, limit };
    } catch (error) {
      console.log(error);
    }
  },
);

export const create = createAsyncThunk(
  'positions/create',
  async ({ data, navigate }, { dispatch }) => {
    try {
      const res = await api.post('/admin/positions', data);
      dispatch(list());
      dispatch(
        setAlert({
          message: 'Position Added successfully',
          alertType: 'success',
        }),
      );
      if (navigate) navigate(`/cms/positions`);
      return res.data;
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);

export const edit = createAsyncThunk(
  'positions/edit',
  async ({ id, data, navigate }, { dispatch }) => {
    try {
      console.log(data);
      await api.put(`/admin/positions/${id}`, data);
      dispatch(
        setAlert({
          message: 'Position updated successfully',
          alertType: 'success',
        }),
      );
      dispatch(list());
      if (navigate) navigate(`/cms/positions`);
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);

export const get = createAsyncThunk(
  'positions/get',
  async (id, { dispatch }) => {
    try {
      const res = await api.get(`/admin/positions/${id}`);
      return res.data;
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);

export const order = createAsyncThunk(
  'positions/order',
  async (data, { dispatch }) => {
    try {
      console.log(data);
      await api.post(`/admin/positions/order`, { list: data });
      dispatch(
        setAlert({
          message: 'positions order updated successfully',
          alertType: 'success',
        }),
      );
      dispatch(
        list({ page: -1, limit: -1, orderBy: 'order', orderDirection: 'ASC' }),
      );
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);

export const remove = createAsyncThunk(
  'positions/remove',
  async (id, { dispatch }) => {
    try {
      await api.delete(`/admin/positions/${id}`);
      dispatch(
        setAlert({
          message: 'Position removed successfully',
          alertType: 'error',
        }),
      );
      dispatch(list());
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);

export const bulk_remove = createAsyncThunk(
  'positions/bulk-remove',
  async ({ data }, { dispatch }) => {
    try {
      await api.post(`/admin/positions/delete`, data);
      dispatch(
        setAlert({
          message: 'Positions removed successfully',
          alertType: 'error',
        }),
      );
      dispatch(list());
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);
