import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { getUser } from "../../features/auth/actions";

const AuthRoute = ({ redirectTo, children }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, user, is_authenticated } = useSelector((state) => state.auth);

    useEffect(() => {
        if (loading && !is_authenticated) {
            dispatch(getUser())
        }
        if (!loading && !is_authenticated) {
            navigate(redirectTo)
        }
    }, [loading, user, is_authenticated, dispatch, redirectTo, navigate])

    return children

}

export default AuthRoute