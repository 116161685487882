import { Box, Button, Divider, Typography } from '@mui/material'
import React, { useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '300px'
};

const center = {
    lat: 25.3548,
    lng: 51.1839
};

const Map = ({ field, value, updateForm, show, onChange }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: field.apiKey,
        nonce: 'sdsdadsaadas'
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return (
        show ?
            <Box mb={3} >
                {field.noLabel !== true && <>
                    <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                        {field?.name?.label ? field?.name?.label : 'Name'}
                    </Typography>
                    <Typography mb={3} >
                        {value.name}
                    </Typography>
                </>}
                <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                    {field?.long?.label ? field?.long?.label : 'Longitude'}
                </Typography>
                <Typography mb={3} >
                    {value.long}
                </Typography>
                <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                    {field?.lat?.label ? field?.lat?.label : 'Latitude'}
                </Typography>
                <Typography mb={3} >
                    {value.lat}
                </Typography>
                {isLoaded && (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{ lat: parseFloat(value.lat), lng: parseFloat(value.long) }}
                        zoom={10}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{ streetViewControl: false }}
                    >
                        {(value.lat && value.long) &&
                            <Marker
                                position={{ lat: parseFloat(value.lat), lng: parseFloat(value.long) }}
                                label={value.name}
                            />}
                    </GoogleMap>
                )}
                <Divider />
            </Box> :
            <>
                {
                    (field.noLabel !== true) &&
                    <div className="form-control">
                        <label className={`form-label ${field.required ? "form-input-required" : ''}`} htmlFor={field?.name?.name ? field?.name?.name : 'name'}>{field?.name?.label ? field?.name?.label : 'Name'}</label>
                        <input className="form-input" type="text" required={field?.required} name={field?.name?.name ? field?.name?.name : 'name'} id={field?.name?.name ? field?.name?.name : 'name'} value={value.name ? value.name : ''} onChange={(e) => updateForm(field.name, { ...value, name: e.target.value ? (e.target.value) : null })} />
                    </div>
                }
                <div className="form-control">
                    <label className={`form-label ${field.required ? "form-input-required" : ''}`} htmlFor={field?.long?.name ? field?.long?.name : 'long'}>{field?.long?.label ? field?.long?.label : 'Longitude'}</label>
                    <input className="form-input" type="number" required={field?.required} name={field?.long?.name ? field?.long?.name : 'long'} id={field?.long?.name ? field?.long?.name : 'long'} value={value.long} onChange={(e) => updateForm(field.name, { ...value, long: e.target.value ? parseFloat(e.target.value) : null })} />
                </div>
                <div className="form-control">
                    <label className={`form-label ${field.required ? "form-input-required" : ''}`} htmlFor={field?.lat?.name ? field?.long?.label : 'lat'}>{field?.lat?.label ? field?.lat?.label : 'Latitude'}</label>
                    <input className="form-input" type="number" required={field?.required} name={field?.lat?.name ? field?.long?.label : 'lat'} id={field?.lat?.name ? field?.long?.label : 'lat'} value={value.lat} onChange={(e) => updateForm(field.name, { ...value, lat: e.target.value ? parseFloat(e.target.value) : null })} />
                </div>
                {isLoaded && (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        onClick={(e) => {
                            let value = {
                                [field?.long?.name ? field?.long?.name : 'long']: e.latLng.lng(),
                                [field?.lat?.name ? field?.lat?.name : 'lat']: e.latLng.lat(),
                            }
                            if (field.noLabel !== true) {
                                value[field?.name?.name ? field?.name?.name : 'name'] = (value.name ? value.name : 'Pin')
                            }
                            updateForm(field.name, value)
                            console.log(value)
                        }}
                        center={center}
                        zoom={10}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{ streetViewControl: false }}
                    >
                        { /* Child components, such as markers, info windows, etc. */}
                        {(value.lat && value.long) &&
                            <Marker
                                position={{ lat: parseFloat(value.lat), lng: parseFloat(value.long) }}
                                label={value.name}
                                draggable={true}
                                onDragEnd={(e) => {
                                    updateForm(field.name, {
                                        ...value,
                                        [field?.long?.name ? field?.long?.name : 'long']: e.latLng.lng(),
                                        [field?.lat?.name ? field?.lat?.name : 'lat']: e.latLng.lat(),
                                    })
                                }}
                            />}
                        <Button variant="contained" sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'white',
                            color: "black",
                            margin: '10px',
                            borderRadius: '2px',
                            fontSize: '18px',
                            fontWeight: 500,
                            padding: "0 17px",
                            height: '40px',
                            fontFamily: 'Roboto, Arial, sans-serif',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: 'rgb(235, 235, 235)',
                            },
                        }}
                            onClick={() => {
                                navigator.geolocation.getCurrentPosition(position => {
                                    console.log(position.coords.latitude, position.coords.longitude);

                                    updateForm(field.name, {
                                        ...value,
                                        [field?.long?.name ? field?.long?.name : 'long']: position.coords.longitude,
                                        [field?.lat?.name ? field?.lat?.name : 'lat']: position.coords.latitude,
                                    })
                                    map.panTo({ lng: position.coords.longitude, lat: position.coords.latitude })
                                    map.setZoom(17)
                                }, error => {
                                    console.log(error)
                                }, { enableHighAccuracy: true })
                            }}
                        >Current</Button>
                    </GoogleMap>
                )}
            </>
    )
}

export default Map