import { Box, Divider, Typography } from '@mui/material'
import React from 'react'

const Email = ({ field, value, onChange, show }) => {
    const { label, name } = field
    return (
        show ?
            <Box mb={3} >
                <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                    {field.label}
                </Typography>
                <Typography mb={3} >
                    {value}
                </Typography>
                <Divider />
            </Box> :
            <div className="form-control">
                <label className={`form-label ${field.required ? "form-input-required" : ''}`} htmlFor={name}>{label}</label>
                <input className="form-input" type="email" required={field?.required} name={name} id={name} value={value} onChange={(e) => onChange(e)} />
            </div>

    )
}

export default Email