const list = {
    header: [
        {
            id: 'name',
            label: 'Name',
            align: 'left',
        },

    ],
    actions: ['bulk remove', 'show', 'delete', 'update'],
};

const search = {
    fields: [
        {
            type: "text",
            name: "name",
        },
    ]
}

const data = { list, search }

export default data;