import { createAsyncThunk } from '@reduxjs/toolkit';
// Import Axios Admin API (this is where the baseURL is and shared reuqest params are set)
import api from '../../utils/api';
import { setAlert } from '../alerts/actions';

export const get = createAsyncThunk(
  'privacy-policy/get',
  async (id, { dispatch }) => {
    try {
      const res = await api.get(`/admin/privacy-policy`);
      return res.data;
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);

export const edit = createAsyncThunk(
  'privacy-policy/edit',
  async ({ data, navigate }, { dispatch }) => {
    try {
      await api.put(`/admin/privacy-policy`, data);
      dispatch(
        setAlert({
          message: 'Privacy policy updated successfully',
          alertType: 'success',
        }),
      );
      navigate(`/cms/privacy policy`);
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert({ message: error.message, alertType: 'error' })),
        );
      }
    }
  },
);
