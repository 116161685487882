import { Stack, Box, Typography } from '@mui/material'

const NotFound = () => {
    return (
        <Stack width='100%' height="100vh" alignContent='center' justifyContent="center" backgroundColor="#F6F7FB">
            <Box textAlign='center'>
                <Typography variant='h1' fontWeight={700} color="primary">404</Typography>
                <Typography variant='h2' fontWeight={300} color="secondary">Page Not Found.</Typography>
            </Box>
        </Stack>
    )
}

export default NotFound