import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumbs from '../../layouts/Breadcrumbs'

import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import Input from '../../layouts/Input'
import { getUser, updateProfile } from '../../../features/auth/actions';
import { setAlert } from '../../../features/alerts/actions';
import { initialState } from '../../../utils/utils';

const Profile = () => {
    const dispatch = useDispatch();

    const fields = useSelector((state) => state.auth.properties.profile.fields);
    const item = useSelector((state) => state.auth.user);



    const [formData, setFormData] = useState(initialState(fields));

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const updateForm = (key, value) => {
        setFormData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (formData.password !== formData.password_confirm) {
            dispatch(setAlert({ message: "Passwords Don't Match", alertType: 'error' }))
        } else {
            console.log(formData);
            dispatch(updateProfile({ data: formData }))
            setFormData({ ...formData, password: '', old_password: '', password_confirm: '' })
        }

    }


    useEffect(() => {
        if (item) {

            let form = initialState(fields);
            for (const key in item) {
                if (form[key] !== undefined) {
                    form = { ...form, [key]: item[key] }
                }
            }
            console.log(form)
            setFormData(form)

        }
    }, [item])

    useEffect(() => {
        if (!item) {
            dispatch(getUser());
        }
    }, []);

    return (
        <div style={{ height: '100%', paddingTop: "4rem" }}>
            <Box height="100%" >
                <Breadcrumbs page={'Profile'} text={'User Profile'} />
                {/* Header */}
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" mb={4}>
                    {/* Title */}
                    <Stack direction="row" spacing={1} alignItems="center" alignSelf={{ xs: "start", sm: "center" }}>
                        <Typography variant="h5" mr={1}>
                            Profile
                        </Typography>
                    </Stack>

                    {/* Buttons */}
                    <Stack direction="row" spacing={2} mt={{ xs: 2, sm: 0 }} alignSelf={{ xs: "end", sm: "center" }}>

                    </Stack>
                </Stack>

                {/* Body */}
                <Paper component='form' elevation={0} mt={2} sx={{ width: '100%', borderRadius: 0, height: { md: "75vh", xs: "60vh" }, padding: 4 }} onSubmit={(e) => onSubmit(e)}>
                    {formData && <Box sx={{ width: '100%', overflowY: 'auto', borderRadius: 0, height: "95%" }}>
                        {fields.map((field, i) => (
                            <Input field={field} onChange={onChange} value={formData[field.name]} key={i} updateForm={updateForm} />
                        ))}
                    </Box>}
                    <Stack direction="row" width="100%" spacing={1} alignItems="center" justifyContent='center'>
                        <Button type="submit" variant="contained" sx={{ borderRadius: 0, padding: '8px 48px', textTransform: 'none' }}>
                            Save
                        </Button>
                    </Stack>
                </Paper>
            </Box>
        </div>
    )
}

export default Profile