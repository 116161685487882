const edit = {
  fields: [
    {
      type: 'mixed',
      name: 'name',
      label: 'Name',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'text',
      name: 'link',
      label: 'Share Link',
    },
    {
      type: 'text',
      name: 'map_link',
      label: 'Map Link',
    },
  ],
  main: true,
  breadcrumb: 'Find Us Settings',
  title: 'Find Us Settings',
};

const data = { edit };

export default data;
