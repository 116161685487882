import React from 'react'
import { Outlet } from 'react-router-dom'
import { list, remove, bulk_remove } from '../../../features/roles/actions';

const index = () => {
    const page = "Roles";
    return (
        <div style={{ height: '100%', paddingTop: "4rem" }}>
            <Outlet context={[{ page, list, remove, bulk_remove }]} />
        </div>
    )
}

export default index