import { Alert, Snackbar, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pop } from '../../features/alerts/slice';

const Alerts = () => {
    const dispatch = useDispatch();
    const alerts = useSelector((state) => state.alerts.list)


    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        dispatch(pop())
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (alerts.length > 0) {
            setOpen(true);
        }
    }, [alerts])



    return (
        <>
            {alerts.length > 0 &&
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={alerts[0].alertType} sx={{ width: '100%' }}>
                        {alerts[0].message}
                    </Alert>
                </Snackbar>
            }

        </>
    )
}

export default Alerts