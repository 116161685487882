const list = {
  header: [
    {
      id: 'email',
      label: 'Email',
      align: 'left',
    },
    {
      id: 'type',
      label: 'Type',
      align: 'left',
    },
  ],
  permission: 'list',
  actions: ['bulk remove', 'delete', 'update'],
};
const create = {
  fields: [
    {
      type: 'email',
      name: 'email',
      label: 'Email',
      required: true,
    },
    {
      type: 'select',
      name: 'type',
      label: 'Type',
      values: [
        { label: 'Contact Us', value: 'Contact Us' },
        { label: 'Quotation', value: 'Quotation' },
        { label: 'Careers', value: 'Careers' }
      ],
      required: true,
    },
  ],
};
const edit = {
  fields: [
    {
      type: 'email',
      name: 'email',
      label: 'Email',
      required: true,
    },
    {
      type: 'select',
      name: 'type',
      label: 'Type',
      values: [
        { label: 'Contact Us', value: 'Contact Us' },
        { label: 'Quotation', value: 'Quotation' },
        { label: 'Careers', value: 'Careers' }
      ],
      required: true,
    },
  ],
};

const search = {
  fields: [
    {
      type: 'text',
      name: 'email',
    },
    {
      type: 'text',
      name: 'type',
    },
  ],
};

const data = { list, create, edit, search };

export default data;
