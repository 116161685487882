import React from 'react';
import { Outlet } from 'react-router-dom';
import { list, get } from '../../../features/contact-us/actions';

const index = () => {
  const page = 'Contact Us';
  return (
    <div style={{ height: '100%', paddingTop: '4rem' }}>
      <Outlet context={[{ page, list, get }]} />
    </div>
  );
};

export default index;
