const edit = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'mixed',
      name: 'title',
      label: 'Title',
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'mixed',
      name: 'text_1',
      label: 'Text 1',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
          rich: true
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
          rich: true
        },
      ],
    },
    {
      type: 'mixed',
      name: 'text_2',
      label: 'Text 2',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
          rich: true
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
          rich: true
        },
      ],
    },
    {
      type: 'mixed',
      name: 'text_3',
      label: 'Text 3',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
          rich: true
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
          rich: true
        },
      ],
    },
    {
      type: 'mixed',
      name: 'quote',
      label: 'Quote',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'image',
      name: 'ceo_image',
      label: 'CEO Image',
      required: true,
    },
    {
      type: 'mixed',
      name: 'ceo_text',
      label: 'CEO Story',
      elements: [
        {
          type: 'text',
          name: 'en',
          rich:true,
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          rich:true,
          label: 'Arabic',
        },
      ],
    },
  ],
  main: true,
  breadcrumb: 'About Us',
  title: 'About Us',
};

const data = { edit };

export default data;
