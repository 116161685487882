import React from 'react';
import { Outlet } from 'react-router-dom';
import { get, edit } from '../../../features/links/actions';

const index = () => {
  const page = 'Links';
  return (
    <div style={{ height: '100%', paddingTop: '4rem' }}>
      <Outlet context={[{ page, get, edit }]} />
    </div>
  );
};

export default index;
