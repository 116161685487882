import { useEffect, useState } from 'react';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumbs from '../../layouts/Breadcrumbs';

import {
  Box,
  Button,
  Chip,
  IconButton,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

// Sortable
import { CSS } from '@dnd-kit/utilities';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import PermissionsManager from '../../layouts/PermissionsManager';
import { authorized } from '../../../utils/utils';

const List = () => {
  const [{ page, list, order }] = useOutletContext();

  const dispatch = useDispatch();
  const { items, itemsLoading, count } = useSelector(
    (state) => state[page.toString().toLowerCase()],
  );
  const fields = useSelector(
    (state) => state[page.toString().toLowerCase()].properties.order.fields,
  );
  const hide = useSelector(
    (state) => state[page.toString().toLowerCase()].properties.order.hide,
  );
  const user = useSelector((state) => state.auth.user);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    dispatch(
      list({ page: -1, limit: -1, orderBy: 'order', orderDirection: 'ASC' }),
    );
  }, []);

  useEffect(() => {
    if (items) setOptions(items);
  }, [items]);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(options);
    dispatch(order(options));
  };

  // Sortable
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setOptions((prev) => {
        const old = prev.find((option) => option.id === active.id);
        const oldIndex = prev.indexOf(old);
        const _new = prev.find((option) => option.id === over.id);
        const newIndex = prev.indexOf(_new);

        return arrayMove(prev, oldIndex, newIndex);
      });
    }
  }

  return (
    user?.Permissions && (
      <PermissionsManager
        action='order'
        name={page.toLowerCase()}
        permissions={user?.Permissions}
      >
        <Box height='100%'>
          {hide?.breadcrumbs !== true && (
            <Breadcrumbs page={page.toString()} text={'Order'} />
          )}
          {/* Header */}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent='space-between'
            alignItems='center'
            mb={4}
          >
            {/* Title */}
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              alignSelf={{ xs: 'start', sm: 'center' }}
            >
              {hide?.back !== true &&
                authorized(user?.Permissions, page.toLowerCase(), 'list') && (
                  <IconButton
                    component='a'
                    href={`/cms/${page.toString().toLowerCase()}`}
                    aria-label='arrow-back'
                    sx={{ border: '2px solid', borderRadius: '50%' }}
                    disableRipple
                    size='small'
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
              {hide?.title !== true && (
                <Typography variant='h5' mr={1}>
                  {'Order'}
                </Typography>
              )}
              <Chip label={count} size='small' />
            </Stack>
          </Stack>

          {/* Body */}
          <Paper
            component='form'
            elevation={0}
            mt={2}
            sx={{
              width: '100%',
              borderRadius: 0,
              height: { md: '75vh', xs: '60vh' },
              padding: 4,
            }}
            onSubmit={(e) => onSubmit(e)}
          >
            <Box
              sx={{
                width: '100%',
                overflowY: 'auto',
                borderRadius: 0,
                height: '95%',
              }}
            >
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={options}
                  strategy={verticalListSortingStrategy}
                >
                  {options.map((option) => (
                    <SortableItem
                      fields={fields}
                      row={option}
                      key={option.id}
                      id={option.id}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </Box>
            <Stack
              direction='row'
              width='100%'
              spacing={1}
              alignItems='center'
              justifyContent='center'
            >
              <Button
                type='submit'
                variant='contained'
                sx={{
                  borderRadius: 0,
                  padding: '8px 48px',
                  textTransform: 'none',
                }}
              >
                Save
              </Button>
            </Stack>
          </Paper>
        </Box>
      </PermissionsManager>
    )
  );
};

function SortableItem(props) {
  const { fields, row, id } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
    padding: 10,
  };

  return (
    <li
      className='order-row'
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {fields.map((column) => {
        const value = row[column.id];
        return (
          <div key={column.id} className='order-column'>
            {column.format && typeof value === 'number' && column.format(value)}
            {column.display ? (
              <img
                src={value}
                alt='item'
                className='img-thumbnail contain'
                style={{ scale: '0.6' }}
              />
            ) : column.child?.length > 0 ? (
              value[column.child]
            ) : (
              value
            )}
          </div>
        );
      })}
    </li>
  );
}

export default List;
