import { useEffect, useState } from 'react';
import {
  Link as RouterLink,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumbs from '../../layouts/Breadcrumbs';

import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Input from '../../layouts/Input';
import { authorized, initialState } from '../../../utils/utils';
import PermissionsManager from '../../layouts/PermissionsManager';

const Form = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const [{ page, list, create, get, edit }] = useOutletContext();

  const properties = useSelector(
    (state) => state[page.toString().toLowerCase()].properties[type],
  );
  const { fields } = properties;
  const user = useSelector((state) => state.auth.user);
  const item = useSelector(
    (state) => state[page.toString().toLowerCase()].item,
  );

  const [formData, setFormData] = useState(null);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const updateForm = (key, value) => {
    setFormData((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    if (type === 'create') {
      dispatch(create({ data: formData, navigate }));
      setFormData(initialState(fields));
    } else {
      dispatch(edit({ id, data: formData, navigate }));
    }
  };

  useEffect(() => {
    if (type === 'edit') {
      dispatch(get(id));
    } else {
      setFormData(initialState(fields));
    }
  }, []);

  useEffect(() => {
    if (item) {
      if (item.id == id || properties.main) {
        let form = initialState(fields);
        for (const key in item) {
          if (form[key] !== undefined) {
            form = { ...form, [key]: item[key] };
          }
        }
        setFormData(form);
      }
    }
  }, [item]);

  return (
    user?.Permissions && (
      <PermissionsManager
        action='form'
        name={page.toLowerCase()}
        permissions={user?.Permissions}
      >
        <Box height='100%'>
          <Breadcrumbs
            page={page.toString()}
            main={properties.main}
            text={
              properties.breadcrumb
                ? properties.breadcrumb
                : type === 'create'
                ? 'Create new'
                : 'Update'
            }
          />
          {/* Header */}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent='space-between'
            alignItems='center'
            mb={4}
          >
            {/* Title */}
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              alignSelf={{ xs: 'start', sm: 'center' }}
            >
              {properties.main &&
              !authorized(user.Permissions, page.toLowerCase(), 'list') ? (
                <></>
              ) : (
                <IconButton
                  component={RouterLink}
                  to={`/cms/${page.toString().toLowerCase()}`}
                  aria-label='arrow-back'
                  sx={{ border: '2px solid', borderRadius: '50%' }}
                  disableRipple
                  size='small'
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Typography variant='h5' mr={1}>
                {properties.title
                  ? properties.title
                  : type === 'create'
                  ? 'Create New'
                  : 'Edit'}
              </Typography>
            </Stack>

            {/* Buttons */}
            <Stack
              direction='row'
              spacing={2}
              mt={{ xs: 2, sm: 0 }}
              alignSelf={{ xs: 'end', sm: 'center' }}
            >
              {/* <Button component={RouterLink} to="add" variant="contained" startIcon={<AddIcon />} sx={{ borderRadius: 0 }}>
                        Edit
                    </Button>
                    <Button variant="outlined" startIcon={<TuneIcon />} sx={{ borderRadius: 0 }} onClick={toggle(true)}  >
                                Filter
                    </Button> */}
            </Stack>
          </Stack>

          {/* Body */}
          <Paper
            component='form'
            elevation={0}
            mt={2}
            sx={{
              width: '100%',
              borderRadius: 0,
              height: { md: '75vh', xs: '60vh' },
              padding: 4,
            }}
            onSubmit={(e) => onSubmit(e)}
          >
            {formData && (
              <>
                <Box
                  sx={{
                    width: '100%',
                    overflowY: 'auto',
                    borderRadius: 0,
                    height: '95%',
                  }}
                >
                  {fields.map((field, i) => (
                    <Input
                      field={field}
                      onChange={onChange}
                      value={formData[field.name]}
                      key={i}
                      updateForm={updateForm}
                      video={
                        field.type === 'background' ? item?.isVideo : undefined
                      }
                    />
                  ))}
                </Box>
                <Stack
                  direction='row'
                  width='100%'
                  spacing={1}
                  alignItems='center'
                  justifyContent='center'
                >
                  <Button
                    type='submit'
                    variant='contained'
                    sx={{
                      borderRadius: 0,
                      padding: '8px 48px',
                      textTransform: 'none',
                    }}
                  >
                    Save
                  </Button>
                </Stack>
              </>
            )}
          </Paper>
        </Box>
      </PermissionsManager>
    )
  );
};

export default Form;
