import React, { useEffect, useState } from 'react'
import { Button, Stack, SwipeableDrawer, Typography, Box } from '@mui/material'
import Input from '../../layouts/Input';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { FilterAlt as FilterAltIcon, FilterAltOff as FilterAltOffIcon, Clear as ClearIcon } from '@mui/icons-material';
import { initialFilterState } from '../../../utils/utils';

const Filter = ({ search, filter, dispatch, list, formData, setFormData, order, setOrder }) => {
    const { fields } = filter
    const { width } = useWindowDimensions();
    const [state, setState] = useState(false);
    const [filtered, setFiltered] = useState(false);
    const toggle = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };


    const onChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const updateForm = (key, value) => {
        setFormData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const data = {};
        for (const key in formData) {
            if (`${formData[key]}`.length > 0) {
                data[key] = formData[key]
                continue;
            }
        }
        console.log(formData);
        console.log(data);
        dispatch(list({ filter: data, search }),);
        setState(false);
        setOrder([]);
        setFiltered(true);
    }

    const clear = (e) => {
        e.preventDefault();
        setFormData(initialFilterState(fields))
        setOrder([]);
        setFiltered(false);
        dispatch(list({ search }));
    }

    useEffect(() => {
        setFormData(initialFilterState(fields));
    }, []);

    return (
        <>
            <Button variant="outlined" startIcon={<FilterAltIcon />} sx={{ borderRadius: 0, textTransform: "none" }} onClick={toggle(true)}  >
                Filter
            </Button>
            {filtered && <Button variant="outlined" startIcon={<FilterAltOffIcon />} sx={{ borderRadius: 0, textTransform: "none" }} onClick={(e) => clear(e)} color="error" >
                Clear
            </Button>}
            <SwipeableDrawer
                anchor={width > 1200 ? "right" : "bottom"}
                open={state}
                onClose={toggle(false)}
                onOpen={toggle(true)}
            >
                <Box
                    component='form'
                    role="presentation"
                    width={{ xs: "100%", lg: "500px" }}
                    height={{ xs: "60vh", lg: "100%" }}
                    sx={{ p: 3 }}
                    onSubmit={(e) => onSubmit(e)}
                >
                    <Stack direction="row" width="100%" mb={3} alignItems="center" justifyContent='space-between'>
                        <Typography variant="h5" >
                            Filter
                        </Typography>
                        <ClearIcon onClick={() => setState(false)} sx={{
                            "&:hover": {
                                cursor: 'pointer'
                            }
                        }} />
                    </Stack>
                    {formData && <>
                        <Box sx={{ width: '100%', overflowY: 'auto', borderRadius: 0, height: { lg: '75vh', xs: '40vh' } }}>
                            {fields.map((field, i) => (
                                <Input field={field} onChange={onChange} value={formData[field.name]} key={i} updateForm={updateForm} />
                            ))}

                        </Box>
                        <Stack direction="row" width="100%" spacing={1} alignItems="center" justifyContent='center'>
                            <Button type="submit" variant="contained" sx={{ borderRadius: 0, padding: '8px 48px', textTransform: 'none' }}>
                                Submit
                            </Button>
                        </Stack>
                    </>}
                </Box>
            </SwipeableDrawer>
        </>
    )
}

export default Filter