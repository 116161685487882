const list = {
  header: [
    {
      id: 'title',
      label: 'Title',
      align: 'left',
    },
    {
      display: true,
      id: 'background',
      type: 'background',
      label: 'Action',
      align: 'left',
    },
  ],
  actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
  fields: [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'slug',
      label: 'Slug',
      required: true,
    },
    {
      type: 'text',
      name: 'subtitle',
      label: 'Subtitle',
      required: true,
    },
    {
      type: 'background',
      name: 'background',
      label: 'Background',
      required: true,
    },
  ],
};
const create = {
  fields: [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'slug',
      label: 'Slug',
      required: true,
    },
    {
      type: 'text',
      name: 'subtitle',
      label: 'Subtitle',
      required: true,
    },
    {
      type: 'background',
      name: 'background',
      label: 'Background',
      required: true,
    },
  ],
};
const edit = {
  fields: [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'slug',
      label: 'Slug',
      required: true,
    },
    {
      type: 'text',
      name: 'subtitle',
      label: 'Subtitle',
      required: true,
    },
    {
      type: 'background',
      name: 'background',
      label: 'Background',
      required: false,
    },
  ],
};

const data = { list, create, edit, show };

export default data;
