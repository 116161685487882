const edit = {
  fields: [
    {
      type: 'email',
      name: 'email',
      label: 'Support Email',
    },
    {
      type: 'tel',
      name: 'phone',
      label: 'Support Tel',
    },
    {
      type: 'text',
      name: 'facebook',
      label: 'Facebook',
    },
    {
      type: 'text',
      name: 'instagram',
      label: 'Instagram',
    },
    {
      type: 'text',
      name: 'linkedin',
      label: 'Linkedin',
    },
    {
      type: 'text',
      name: 'twitter',
      label: 'Twitter',
    },
    {
      type: 'text',
      name: 'google',
      label: 'Google',
    },
    {
      type: 'text',
      name: 'youtube',
      label: 'Youtube',
    },
    {
      type: 'text',
      name: 'link',
      label: 'Saudi Exchange',
    },
  ],
  main: true,
  breadcrumb: 'Links Settings',
  title: 'Links Settings',
};

const data = { edit };

export default data;
