import { Box, Divider, IconButton, Typography } from '@mui/material';
import Icon from '../layouts/Icon';
import { useState } from 'react';

const Password = ({ field, value, onChange, confirm, show }) => {
    const { label, name } = field
    const [visibility, setVisibility] = useState(() => {
        let s = { password: true }
        if (field.confirm) s['confirm'] = true;
        return s
    })
    return (
        show ?
            <Box mb={3} >
                <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                    {field.label}
                </Typography>
                <Typography mb={3} >
                    {value}
                </Typography>
                <Divider />
            </Box> : <>
                <div className="form-control">
                    <label className={`form-label ${field.required ? "form-input-required" : ''}`} htmlFor={name}>{label}</label>
                    <div className="form-input-password">
                        <input type={visibility.password ? "password" : "text"} required={field?.required} name={name} id={name} value={value} onChange={(e) => onChange(e)} />
                        <IconButton
                            edge="start"
                            onClick={() => { setVisibility({ ...visibility, password: !visibility.password }) }}
                            size="small"
                            sx={{ padding: 0 }}
                            disableRipple
                        >

                            {visibility.password ?<Icon type={'Visibility'} color={'gray'} /> : <Icon type={'VisibilityOff'} color={'gray'} />}
                        </IconButton>
                    </div>
                </div>
                {field.confirm && <div className="form-control">
                    <label className={`form-label ${field.required ? "form-input-required" : ''}`} htmlFor={`${name}_confirm`}>{`${label} Confirm`}</label>
                    <div className="form-input-password">
                        <input type={visibility.confirm ? "password" : "text"} required={field?.required} name={`${name}_confirm`} id={`${name}_confirm`} value={confirm} onChange={(e) => onChange(e)} />
                        <IconButton
                            edge="start"
                            onClick={() => { setVisibility({ ...visibility, confirm: !visibility.confirm }) }}
                            size="small"
                            sx={{ padding: 0 }}
                            disableRipple
                        >
                            {visibility.confirm ? <Icon type={'Visibility'} color={'gray'} /> : <Icon type={'VisibilityOff'} color={'gray'} />}
                        </IconButton>
                    </div>
                </div>}
            </>
    )
}

export default Password