import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../utils/api";
import { setAuthToken } from "../../utils/setAuthToken";
import { setAlert } from "../alerts/actions";

export const login = createAsyncThunk("/auth/login", async ({ data, navigate }, { dispatch, rejectWithValue }) => {
    try {
        const { token } = await api.post("/admin/auth/login", data);
        setAuthToken(token);
        dispatch(getUser());
        navigate('/cms');
        return token;
    } catch (err) {
        console.log(1)
        const errors = err.response.data.errors;
        console.log(errors);
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
        return rejectWithValue('Error');
    }

});

export const getUser = createAsyncThunk("/auth/getUser", async (data, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await api.get("/admin/auth/me");
        return data;
    } catch (err) {
        // const errors = err.response.data.errors;
        // console.log(errors);
        // if (errors) {
        //     errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        // }
        return rejectWithValue('Error');
    }
});

export const updateProfile = createAsyncThunk("admins/updateProfile", async ({ data }, { dispatch }) => {
    try {
        console.log(data);
        await api.put(`/admin/auth/profile`, data);
        dispatch(setAlert({ message: 'Profile updated successfully', alertType: 'success' }))

    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});
export const logout = createAsyncThunk("/auth/logout", async (data, { dispatch, rejectWithValue }) => {
    try {
        await api.get("/admin/auth/logout");
        setAuthToken(null)
        return true
    } catch (err) {
        const errors = err.response.data.errors;
        console.log(errors);
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
        return rejectWithValue('Error');
    }
});

