import React from 'react';
import Text from '../inputs/Text';
import Email from '../inputs/Email';
import Number from '../inputs/Number';
import Select from '../inputs/Select';
import Password from '../inputs/Password';
import Phone from '../inputs/Phone';
import Image from '../inputs/Image';
import Video from '../inputs/Video';
import File from '../inputs/File';
import Switch from '../inputs/Switch';
import Mixed from '../inputs/Mixed';
import Empty from '../inputs/Empty';
import Map from '../inputs/Map';
import Date from '../inputs/Date';
import Background from '../inputs/Background';

const Input = ({
  field,
  onChange,
  value,
  updateForm,
  confirm,
  show,
  video,
}) => {
  if (show && value === '') {
    return <Empty label={field.label} value={value} />;
  }

  switch (field.type) {
    case 'text':
      return (
        <Text
          field={field}
          onChange={onChange}
          value={value}
          updateForm={updateForm}
          show={show}
        />
      );
    case 'date':
      return (
        <Date
          field={field}
          onChange={onChange}
          value={value}
          updateForm={updateForm}
          show={show}
        />
      );
    case 'email':
      return (
        <Email field={field} onChange={onChange} value={value} show={show} />
      );
    case 'number':
      return (
        <Number field={field} onChange={onChange} value={value} show={show} />
      );
    case 'mixed':
      return (
        <Mixed
          field={field}
          onChange={onChange}
          value={value}
          show={show}
          updateForm={updateForm}
        />
      );
    case 'select':
      return (
        <Select
          field={field}
          onChange={onChange}
          value={value}
          show={show}
          updateForm={updateForm}
        />
      );
    case 'image':
      return (
        <Image
          field={field}
          onChange={onChange}
          value={value}
          show={show}
          updateForm={updateForm}
        />
      );
    case 'video':
      return (
        <Video
          field={field}
          onChange={onChange}
          value={value}
          show={show}
          updateForm={updateForm}
        />
      );
    case 'background':
      return (
        <Background
          field={field}
          onChange={onChange}
          value={value}
          show={show}
          updateForm={updateForm}
          video={video}
        />
      );
    case 'file':
      return (
        <File
          field={field}
          onChange={onChange}
          value={value}
          show={show}
          updateForm={updateForm}
        />
      );
    case 'switch':
      return (
        <Switch
          field={field}
          onChange={onChange}
          value={value}
          show={show}
          updateForm={updateForm}
        />
      );
    case 'password':
      return (
        <Password
          field={field}
          onChange={onChange}
          value={value}
          show={show}
          confirm={confirm}
        />
      );
    case 'tel':
      return (
        <Phone field={field} onChange={onChange} value={value} show={show} />
      );
    case 'map':
      return (
        <Map
          field={field}
          onChange={onChange}
          value={value}
          show={show}
          updateForm={updateForm}
        />
      );
    default:
      return 'Invalid Input Type';
  }
};

export default Input;
