import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  list,
  create,
  edit,
  remove,
  get,
  bulk_remove,
} from '../../../features/recipients/actions';

const index = () => {
  const page = 'Recipients';
  return (
    <div style={{ height: '100%', paddingTop: '4rem' }}>
      <Outlet
        context={[{ page, list, create, get, edit, remove, bulk_remove }]}
      />
    </div>
  );
};

export default index;
