// import api from './api';
// import axios from 'axios';

export const setAuthToken = (token) => {
    if (token) {
        // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        localStorage.setItem('token', token);
    } else {
        console.log("delete token");
        // delete api.defaults.headers.common['Authorization'];
        localStorage.removeItem('token');
    }
};

