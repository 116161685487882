const list = {
  header: [
    {
      id: 'image',
      display: true,
      label: 'Image',
      align: 'left',
    },
    {
      id: 'Category.name.en',
      label: 'Category',
      order: false,
      align: 'left',
    },
    {
      id: 'title.en',
      label: 'Title',
      orderBy: 'title.en',
      align: 'left',
    },
    {
      id: 'publish',
      label: 'Publish',
      align: 'left',
      switch: true,
    },
    {
      id: 'pin',
      label: 'Pin',
      align: 'left',
      switch: true,
    },
  ],
  permission: 'list',
  actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'text',
      name: 'Category.name.en',
      label: 'Category',
    },
    {
      type: 'mixed',
      name: 'title',
      label: 'Title',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'text',
      name: 'size',
      label: 'Size',
      required: false,
    },
    {
      type: 'text',
      name: 'weight',
      label: 'Weight',
      required: false,
    },
    {
      type: 'mixed',
      name: 'body',
      label: 'Body',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
          rich: true,
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
          rich: true,
        },
      ],
    },
    {
      type: 'file',
      name: 'media',
      label: 'Media',
      required: false,
    },

    {
      type: 'switch',
      name: 'publish',
      label: 'Published',
      required: true,
    },
    {
      type: 'switch',
      name: 'pin',
      label: 'Pin',
      required: true,
    },
  ],
  permission: 'show',
};
const create = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'select',
      async: true,
      name: 'category_id',
      label: 'Category',
      required: true,
      route: 'categories',
    },
    {
      type: 'mixed',
      name: 'title',
      label: 'Title',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'text',
      name: 'size',
      label: 'Size',
      required: true,
    },
    {
      type: 'text',
      name: 'weight',
      label: 'Weight',
      required: true,
    },
    {
      type: 'mixed',
      name: 'body',
      label: 'Body',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
          rich: true,
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
          rich: true,
        },
      ],
    },
    {
      type: 'file',
      name: 'media',
      label: 'Media',
      required: false,
    },
  ],
};
const edit = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'select',
      async: true,
      name: 'category_id',
      label: 'Category',
      required: true,
      route: 'categories',
    },
    {
      type: 'mixed',
      name: 'title',
      label: 'Title',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
        },
      ],
    },
    {
      type: 'text',
      name: 'size',
      label: 'Size',
      required: true,
    },
    {
      type: 'text',
      name: 'weight',
      label: 'Weight',
      required: true,
    },
    {
      type: 'mixed',
      name: 'body',
      label: 'Body',
      required: true,
      elements: [
        {
          type: 'text',
          name: 'en',
          label: 'English',
          rich: true,
        },
        {
          type: 'text',
          name: 'ar',
          label: 'Arabic',
          rich: true,
        },
      ],
    },
    {
      type: 'file',
      name: 'media',
      label: 'Media',
      required: false,
    },
  ],
};

const search = {
  fields: [
    {
      type: 'text',
      name: 'title.en',
    },
    {
      type: 'text',
      name: 'body.en',
    },
    {
      type: 'text',
      name: 'size',
    },
    {
      type: 'text',
      name: 'weight',
    },
  ],
};

const order = {
  fields: [
    {
      id: 'image',
      display: true,
      label: 'Image',
      align: 'left',
    },
    {
      id: 'title',
      child: 'en',
      label: 'Title',
      align: 'left',
    },
  ],
  permission: 'order',
};

const data = { list, create, edit, show, search, order };

export default data;
