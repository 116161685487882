import { configureStore } from '@reduxjs/toolkit';

import adminsReducer from './features/admins/slice';
import permissionsReducer from './features/permissions/slice';
import rolesReducer from './features/roles/slice';
import quotesReducer from './features/quotes/slice';
import stepsReducer from './features/steps/slice';
import applicationsReducer from './features/applications/slice';
import recipientsReducer from './features/recipients/slice';
import manufacturingReducer from './features/manufacturing/slice';
import sustainabilityReducer from './features/sustainability/slice';
import bannersReducer from './features/banners/slice';
import categoriesReducer from './features/categories/slice';
import positionsReducer from './features/positions/slice';
import faqsReducer from './features/faqs/slice';
import productsReducer from './features/products/slice';
import partnersReducer from './features/partners/slice';
import aboutUsReducer from './features/about-us/slice';
import joinUsReducer from './features/join-us/slice';
import newsReducer from './features/news/slice';
import privacyPolicyReducer from './features/privacy-policy/slice';
import homeInformativeReducer from './features/home-informative/slice';
import homeItemsReducer from './features/home-items/slice';
import authReducer from './features/auth/slice';
import alertsReducer from './features/alerts/slice';
import missionReducer from './features/mission/slice';
import sidebarReducer from './features/sidebar/slice';
import smtpReducer from './features/smtp/slice';
import scoreCardsReducer from './features/score-cards/slice';
import supportReducer from './features/support/slice';
import challengesReducer from './features/challenges/slice';
import climateChangeReducer from './features/climate-change/slice';
import termsReducer from './features/terms/slice';
import linksReducer from './features/links/slice';
import findusReducer from './features/findus/slice';
import contactUsReducer from './features/contact-us/slice';

export const store = configureStore({
  // Add the slices to the store so they can be accessed by any components on the app
  reducer: {
    admins: adminsReducer,
    alerts: alertsReducer,
    permissions: permissionsReducer,
    roles: rolesReducer,
    steps: stepsReducer,
    mission: missionReducer,
    manufacturing: manufacturingReducer,
    sustainability: sustainabilityReducer,
    quotes: quotesReducer,
    applications: applicationsReducer,
    auth: authReducer,
    sidebar: sidebarReducer,
    smtp: smtpReducer,
    categories: categoriesReducer,
    products: productsReducer,
    positions: positionsReducer,
    faqs: faqsReducer,
    'find us': findusReducer,
    'score cards': scoreCardsReducer,
    'join us': joinUsReducer,
    'contact us': contactUsReducer,
    support: supportReducer,
    terms: termsReducer,
    links: linksReducer,
    challenges: challengesReducer,
    recipients: recipientsReducer,
    'climate change': climateChangeReducer,
    banners: bannersReducer,
    partners: partnersReducer,
    news: newsReducer,
    'home informative': homeInformativeReducer,
    'about us': aboutUsReducer,
    'privacy policy': privacyPolicyReducer,
    'home items': homeItemsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
