import { createSlice } from '@reduxjs/toolkit';

// State on app load and refresh
const initialState = {
  list: [
    {
      label: 'Adminstration',
      icon: 'Shield',
      children: [
        {
          label: 'Admins',
          icon: 'SupervisorAccount',
          route: 'admins',
        },
        {
          label: 'Roles',
          icon: 'AddModerator',
          route: 'roles',
        },
        {
          label: 'Permissions',
          icon: 'AdminPanelSettings',
          route: 'permissions',
        },
      ],
    },
    {
      label: 'Banners',
      icon: 'ViewCarousel',
      route: 'banners',
    },
    {
      label: 'Home',
      icon: 'Home',
      children: [
        {
          label: 'Informative',
          icon: 'Article',
          route: 'home informative',
          permission: 'form',
        },
        {
          label: 'Items',
          icon: 'Category',
          route: 'home items',
        },
        {
          label: 'Partners',
          icon: 'Handshake',
          route: 'partners',
        },
      ],
    },
    {
      label: 'Products',
      icon: 'ShoppingCart',
      children: [
        {
          label: 'Categories',
          icon: 'Category',
          route: 'categories',
        },
        {
          label: 'Products',
          icon: 'Inventory',
          route: 'products',
        },
        {
          label: 'Quotes',
          icon: 'RequestQuote',
          route: 'quotes',
        },
      ],
    },

    {
      label: 'News',
      icon: 'Newspaper',
      route: 'news',
    },
    {
      label: 'Careers',
      icon: 'Work',
      children: [
        {
          label: 'Join Us',
          icon: 'Info',
          route: 'join us',
          permission: 'form',
        },
        {
          label: 'Positions',
          icon: 'Attribution',
          route: 'positions',
        },
        {
          label: 'Applications',
          icon: 'ThreeP',
          route: 'applications',
        },
      ],
    },
    {
      label: 'Glass Manufacturing',
      icon: 'WineBar',
      children: [
        {
          label: 'Informative',
          icon: 'Info',
          route: 'manufacturing',
          permission: 'form',
        },
        {
          label: 'Steps',
          icon: 'LooksOne',
          route: 'steps',
        },
      ],
    },
    {
      label: 'Sustainability',
      icon: 'Recycling',
      children: [
        {
          label: 'Informative',
          icon: 'Info',
          route: 'sustainability',
          permission: 'form',
        },
        {
          label: 'Score Cards',
          icon: 'Dashboard',
          route: 'score cards',
        },
        {
          label: 'Climate Change',
          icon: 'List',
          route: 'climate change',
        },
        {
          label: 'Challenges',
          icon: 'List',
          route: 'challenges',
        },
      ],
    },
    {
      label: 'Contact Us',
      icon: 'ContactSupport',
      route: 'contact us',
    },
    {
      label: 'About Us',
      icon: 'Info',
      route: 'about us',
      permission: 'form',
    },
    {
      label: 'Mission & Vision',
      icon: 'Info',
      route: 'mission',
      permission: 'form',
    },
    {
      label: 'Privacy Policy',
      icon: 'ContactSupport',
      route: 'privacy policy',
      permission: 'form',
    },
    {
      label: 'Terms and Conditions',
      icon: 'ContactSupport',
      route: 'terms',
      permission: 'form',
    },
    {
      label: 'Support',
      icon: 'SupportAgent',
      route: 'support',
      permission: 'form',
    },
    {
      label: 'FAQs',
      icon: 'Help',
      route: 'faqs',
    },

    {
      label: 'Settings',
      icon: 'Settings',
      children: [
        {
          label: 'SMTP',
          icon: 'Mail',
          route: 'smtp',
          permission: 'form',
        },
        {
          label: 'Find Us',
          icon: 'Directions',
          route: 'find us',
          permission: 'form',
        },
        {
          label: 'Links',
          icon: 'SupportAgent',
          route: 'links',
          permission: 'form',
        },
        {
          label: 'Social Media',
          icon: 'Share',
          route: 'social media',
          permission: 'form',
        },
        {
          label: 'Email Recipients',
          icon: 'Mail',
          route: 'recipients',
        },
      ],
    },
  ], // will hold the list of items
};

// Create the Users Slice
const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {},
});

// Export the slice reducer
export default sidebarSlice.reducer;
