import React from 'react'

import { Box, Link, Stack, Typography } from '@mui/material'

const Footer = ({ direction, justifyContent }) => {
    return (
        <Stack direction={direction ? direction : { lg: 'row', xs: 'column' }} justifyContent={justifyContent ? justifyContent : { lg: "space-between", xs: 'center' }} alignItems="center" marginY={3}>
            <Box>
                <Typography color="#00000099" fontSize='small'>&copy; 2023 Powered by <Link href="https://www.tedmob.com" underline="none" fontWeight='bold'>TEDMOB.com</Link></Typography>
            </Box>
            <Box>
                <Typography color="#00000099" fontStyle='italic' fontSize='small'>"Simplicity is the soul of efficiency." - <b>Austin Freeman</b></Typography>
            </Box>
        </Stack>
    )
}

export default Footer