import { useNavigate } from "react-router-dom";
import { authorized } from "../../utils/utils";

const PermissionsManager = ({ children, action, name, permissions }) => {

    const navigate = useNavigate();
    if (!authorized(permissions, name, action)) navigate('/unauthorized')
    return children
}

export default PermissionsManager