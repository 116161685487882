import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Input from '../layouts/Input';

const Mixed = ({ field, value, onChange, updateForm, show }) => {
    const { label, name, elements } = field

    const [mixedFormData, setMixedFormData] = useState(value);


    const onChangeMix = (e) => {
        setMixedFormData({ ...mixedFormData, [e.target.id]: e.target.value });
    };

    const updateFormMix = (key, value) => {
        setMixedFormData((prev) => {
            return { ...prev, [key]: value };
        });
    };

    useEffect(() => {
        if (!show)
            updateForm(name, mixedFormData)
    }, [mixedFormData])

    return (
        mixedFormData && <div className="mix-container">

            {show ? <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                {field.label}
            </Typography> : <label className={`form-label ${field.required ? "form-input-required" : ''}`} htmlFor={name}><b>{label}</b></label>}
            <div className="mix-control">
                {elements.map((element, i) => (
                    <Input field={element} onChange={onChangeMix} value={mixedFormData[element.name]} key={i} updateForm={updateFormMix} show={show} />
                ))}
            </div>
        </div>
    )
}

export default Mixed